// Functions first
@import "bootstrap/scss/functions";

// Variable overrides second
$enable-dark-mode: false !default;

// Required Bootstrap + Barceloneta imports
@import "@plone/plonetheme-barceloneta-base/scss/variables.colors.plone";
@import "@plone/plonetheme-barceloneta-base/scss/variables.properties";
@import "@plone/plonetheme-barceloneta-base/scss/variables.barceloneta";

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "@plone/plonetheme-barceloneta-base/scss/root_variables";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.portlets.plone";
@import "@plone/plonetheme-barceloneta-base/scss/mixins/mixin.font.plone";

// Optional components
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/helpers";
@import "bootstrap/scss/utilities/api";

// Bootstrap components
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/offcanvas";

// Barceloneta
@import "@plone/plonetheme-barceloneta-base/scss/alerts";
@import "@plone/plonetheme-barceloneta-base/scss/toolbar";

// Add additional custom code here
ul.plone-toolbar-main {
  margin-left: 0;
}

#edit-zone ul li,
ul.plone-toolbar-main li {
  margin-bottom: 0;
  padding: 0;
  font-size: 12px;
}
#edit-zone ul .nav-link,
ul.plone-toolbar-main .nav-link {
  padding: 8px;
  padding-left: 16px;
}

#edit-zone .dropdown-header {
  font-size: 1.5rem;
  font-weight: 700;
}
